// AdminLogin.js noy
//import React from 'react';
import { Container, Paper, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import axios from "axios";
const AdminSignup = () => {
  const [admin, setAdmin] = useState({
    password: "",
    username: "",
    secretKey: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);

  const history = useNavigate();

  const handleSignup = async () => {
    setLoading(true);
    try {
      const res = await axios.post("https://solanarootlab-94e7d0d3206e.herokuapp.com/api/users", {
        name: admin.username,
        email: admin.email,
        password: admin.password,
        secretKey: admin.secretKey,
      });
      console.log({ res });
      if (res) {
        setLoading(false);
        console.log(res);
        localStorage.setItem("phantom_admin", JSON.stringify(res.data));
        alert("Register Successfully");
        history("/admin-page");
      }
    } catch (e) {
      console.log({ e });
      alert(e);
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: "50px" }}>
      <Paper elevation={3} style={{ padding: "20px" }}>
        <h1>3D rootlabs Admin Signup</h1>
        <TextField
          label="Username"
          onChange={(e) => {
            setAdmin((prev) => ({
              ...prev,
              username: e.target.value,
            }));
          }}
          value={admin.username}
          variant="outlined"
          margin="normal"
          fullWidth
          id="username"
        />
        <TextField
          label="Email"
          name="email"
          onChange={(e) => {
            setAdmin((prev) => ({
              ...prev,
              email: e.target.value,
            }));
          }}
          value={admin.email}
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
        />
        <TextField
          name="secretKey"
          onChange={(e) => {
            setAdmin((prev) => ({
              ...prev,
              secretKey: e.target.value,
            }));
          }}
          value={admin.secretKey}
          label="Secret Key"
          variant="outlined"
          margin="normal"
          fullWidth
          id="secretKey"
        />
        <TextField
          onChange={(e) => {
            setAdmin((prev) => ({
              ...prev,
              password: e.target.value,
            }));
          }}
          value={admin.password}
          label="Password"
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          id="password"
        />
        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          onClick={() => history("/")}
          sx={{
            cursor: "pointer",
            userSelect: "none",
            ml: -1,
            display: "flex",
            justifyContent: "flex-end",
            textDecoration: "underline",
          }}
        >
          Login as User
        </MDTypography>
        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          onClick={() => history("/admin/login")}
          sx={{
            cursor: "pointer",
            userSelect: "none",
            ml: -1,
            display: "flex",
            justifyContent: "flex-end",
            textDecoration: "underline",
          }}
        >
          Login as Admin
        </MDTypography>
        <Button variant="contained" sx={{ color: "white" }} onClick={handleSignup}>
          Signup
        </Button>
      </Paper>
    </Container>
  );
};

export default AdminSignup;
