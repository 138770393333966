// AdminLogin.js noy
//import React from 'react';
import { Container, Paper, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import axios from "axios";
const AdminLogin = () => {
  const [admin, setAdmin] = useState({
    password: "",
    username: "",
  });

  const handleLogin = async () => {
    try {
      const res = await axios.post(
        "https://solanarootlab-94e7d0d3206e.herokuapp.com/api/users/login",
        {
          password: admin.password,
          email: admin.username,
        }
      );
      console.log({ res });
      if (res) {
        console.log(res);
        localStorage.setItem("phantom_admin", JSON.stringify(res.data));
        alert("Login Successfully");
        history("/admin-page");
      }
    } catch (e) {
      console.log({ e });
      alert(e);
    }
  };
  const history = useNavigate();

  return (
    <Container maxWidth="sm" style={{ marginTop: "50px" }}>
      <Paper elevation={3} style={{ padding: "20px" }}>
        <h1>3D rootlabs Admin Login</h1>
        <TextField
          name="username"
          type="text"
          onChange={(e) => {
            setAdmin((prev) => ({
              ...prev,
              username: e.target.value,
            }));
          }}
          value={admin.username}
          label="Username"
          variant="outlined"
          margin="normal"
          fullWidth
          id="username"
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={(e) => {
            setAdmin((prev) => ({
              ...prev,
              password: e.target.value,
            }));
          }}
          value={admin.password}
          id="password"
        />
        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          onClick={() => history("/admin/signup")}
          sx={{
            cursor: "pointer",
            userSelect: "none",
            ml: -1,
            display: "flex",
            justifyContent: "flex-end",
            textDecoration: "underline",
          }}
        >
          Sign up as Admin
        </MDTypography>
        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          onClick={() => history("/")}
          sx={{
            cursor: "pointer",
            userSelect: "none",
            ml: -1,
            display: "flex",
            justifyContent: "flex-end",
            textDecoration: "underline",
          }}
        >
          Log in as user
        </MDTypography>
        <Button variant="contained" color="primary" onClick={handleLogin}>
          Login
        </Button>
      </Paper>
    </Container>
  );
};

export default AdminLogin;
