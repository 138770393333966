import React, { useEffect, useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const OrderForm = ({ getAllOrders }) => {
  const navigate = useNavigate();
  const [orderNumber, setOrderNumber] = useState("");
  const [orderEmail, setOrderEmail] = useState("");
  const [orderPlatform, setOrderPlatform] = useState("");

  const handleOrder = async () => {
    // Access orderNumber and orderingPlatform here
    try {
      const user = JSON.parse(localStorage.getItem("phantom_user"));
      console.log({ user });
      if (!user) {
        alert("please login first");
      }
      if (orderEmail && orderPlatform && orderNumber && user) {
        const res = await axios.post(
          "https://solanarootlab-94e7d0d3206e.herokuapp.com/api/users/order-number",
          {
            orderEmail,
            orderPlatform,
            orderNumber,
            userId: user._id,
            email: user.email,
          }
        );
        console.log({ res });
        setOrderNumber("");
        setOrderPlatform("");
        setOrderEmail("");
        alert("order placed successfully");
        getAllOrders();
      } else {
        alert("please fill all input");
      }
    } catch (e) {
      alert(e.response.data.message);
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Order number Submission Page
      </Typography>
      <TextField
        label="Enter your order number"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        value={orderNumber}
        onChange={(e) => setOrderNumber(e.target.value)}
      />
      <TextField
        label="Enter the ordering platform e.g ebay,aliexpress,amazon "
        variant="outlined"
        margin="normal"
        fullWidth
        required
        value={orderPlatform}
        onChange={(e) => setOrderPlatform(e.target.value)}
      />
      <TextField
        label="Enter your email address "
        variant="outlined"
        margin="normal"
        fullWidth
        required
        value={orderEmail}
        onChange={(e) => setOrderEmail(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        style={{ marginLeft: "10px", color: "white" }}
        onClick={handleOrder}
      >
        Submit Order
      </Button>
      {/* <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: '10px',color:"white" }}
                onClick={handleAdminLogin}
            >
                Admin Login
            </Button> */}
    </div>
  );
};

export default OrderForm;
