import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Table from "../components/table";
import { Container, Paper } from "@mui/material";
import OrderForm from "./OrderForm";
import OrderAssignedSection from "./OrderAssignedSection";
import axios from "axios";

const Wrapper = styled.div`
  margin-left: 266px;
  padding: 2rem 1rem;
  @media (max-width: 1200px) {
    margin-left: unset;
  }
`;

export default function NFT() {
  const [assignedOrders, setAssignedOrders] = useState([]);
  useEffect(() => {
    getAllOrders();
  }, []);

  const getAllOrders = async () => {
    const user = JSON.parse(localStorage.getItem("phantom_user"));
    const res = await axios.get(
      "https://solanarootlab-94e7d0d3206e.herokuapp.com/api/users/orders",
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    setAssignedOrders(res.data);
  };
  return (
    <Container maxWidth="sm" style={{ marginTop: "10px" }}>
      <Paper elevation={3} style={{ padding: "20px" }}>
        <OrderForm getAllOrders={getAllOrders} />
      </Paper>

      <OrderAssignedSection assignedOrders={assignedOrders} />
    </Container>
  );
}
