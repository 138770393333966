import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TableContainer,
  Paper,
} from "@mui/material";
import { actions } from "@metaplex/js";
import { AnchorProvider } from "@project-serum/anchor";
import * as anchor from "@project-serum/anchor";
import axios from "axios";
import idl from "./idl.json";
import { SolanaClusterContext } from "providers/SolanaCluster";
import React, { useContext, useEffect, useState } from "react";
import { Connection, PublicKey, SystemProgram } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID, getAssociatedTokenAddress } from "@solana/spl-token";

const AdminPage = () => {
  useEffect(() => {
    getAllOrders();
  }, []);
  const [orders, setOrders] = useState([]);
  const [orderIdToDelete, setOrderIdToDelete] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { connection, changeSolanaCluster } = useContext(SolanaClusterContext);
  const [categories, setCategories] = useState([
    "12 Pot w. out lights",
    "20 Pot w. out lights",
    "28 Pot w. out lights",
    "40 Pot w. out lights",
    "12 Pot w. lights",
    "20 Pot w. lights",
    "28 Pot w. lights",
    "40 Pot w. lights",
    "56 Pot Commercial",
  ]);
  const rejectNFT = async () => {
    const user = JSON.parse(localStorage.getItem("phantom_admin"));
    const updatedOrders = orders.map((order) => {
      if (order._id === selectedOrder) {
        setCategories((prev) => {
          return [...prev, order.category];
        });
        return { ...order, status: "Rejected", category: "" };
      }
      return order;
    });
    const res = await axios.post(
      "https://solanarootlab-94e7d0d3206e.herokuapp.com/api/users/admin/update-order",
      {
        orderID: selectedOrder,
        status: "Rejected",
        category: "",
      },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    alert(res.data.message);
    handleConfirmation(true);
    setOpenModal(false);
    setOrders(updatedOrders);
  };
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const getAllOrders = async () => {
    const user = JSON.parse(localStorage.getItem("phantom_admin"));
    const res = await axios.get(
      "https://solanarootlab-94e7d0d3206e.herokuapp.com/api/users/admin/orders",
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    console.log(res.data);
    setOrders(res.data);
    // setAssignedOrders(res.data);
  };

  const handleCategorySelection = (category) => {
    // Set the selected category
    setSelectedCategory(category);
  };

  const handleAssignCategory = async () => {
    if (selectedOrder && selectedCategory) {
      // Update the order with the assigned category and status
      const updatedOrders = orders.map((order) => {
        if (order._id === selectedOrder) {
          return { ...order, category: selectedCategory, status: "Accepted" };
        }
        return order;
      });
      setOrders(updatedOrders);
      const user = JSON.parse(localStorage.getItem("phantom_admin"));
      setCategories(categories.filter((c) => c !== selectedCategory));
      const res = await axios.post(
        "https://solanarootlab-94e7d0d3206e.herokuapp.com/api/users/admin/update-order",
        {
          orderID: selectedOrder,
          status: "Accepted",
          category: selectedCategory,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      alert(res.data.message);
      // Close the dialog and reset selected order and category
      setSelectedOrder(null);
      setOpenModal(false);
      setSelectedCategory(null);
    }
  };
  const closeModal = () => {
    setSelectedOrder(null);
    setOpenModal(false);
  };

  const getMetadata = async (mint, TOKEN_METADATA_PROGRAM_ID) => {
    return (
      await anchor.web3.PublicKey.findProgramAddress(
        [Buffer.from("metadata"), TOKEN_METADATA_PROGRAM_ID.toBuffer(), mint.toBuffer()],
        TOKEN_METADATA_PROGRAM_ID
      )
    )[0];
  };

  const getMasterEdition = async (mint, TOKEN_METADATA_PROGRAM_ID) => {
    return (
      await anchor.web3.PublicKey.findProgramAddress(
        [
          Buffer.from("metadata"),
          TOKEN_METADATA_PROGRAM_ID.toBuffer(),
          mint.toBuffer(),
          Buffer.from("edition"),
        ],
        TOKEN_METADATA_PROGRAM_ID
      )
    )[0];
  };

  const getProvider = async () => {
    const wallet = window.solana;

    const provider = new AnchorProvider(connection, wallet, { commitment: "processed" });
    return provider;

    // const connection = new Connection("https://api.devnet.solana.com");
  };
  const sendTransaction = async () => {
    const { solana } = window;
    const response = await solana.connect();
    const provider = await getProvider();
    const programId = new PublicKey("DfxRVABAcSLVN9chWpxJMVU8RW9LwYDt8CqDiQi7bURA"); // Replace with your actual program ID
    const TOKEN_METADATA_PROGRAM_ID = new anchor.web3.PublicKey(response.publicKey.toString());
    const mintKey = anchor.web3.Keypair.generate();
    const program = new anchor.Program(idl, programId, provider);
    const metaDataAddress = await getMetadata(mintKey.publicKey, TOKEN_METADATA_PROGRAM_ID);
    const masterEdition = await getMasterEdition(mintKey.publicKey, TOKEN_METADATA_PROGRAM_ID);
    const NftTokenAccount = await getAssociatedTokenAddress(mintKey.publicKey, response.publicKey);
    const tx = await program.rpc.mintNft(
      mintKey.publicKey,
      "https://arweave.net/y5e5DJsiwH0s_ayfMwYk-SnrZtVZzHLQDSTZ5dNRUHA",
      "NFT Title",
      {
        accounts: {
          mintAuthority: provider.wallet.publicKey,
          mint: mintKey.publicKey,
          tokenAccount: NftTokenAccount,
          tokenProgram: TOKEN_PROGRAM_ID,
          metadata: metaDataAddress,
          tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
          payer: provider.wallet.publicKey,
          systemProgram: SystemProgram.programId,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          masterEdition: masterEdition,
        },
      }
    );
    console.log({ tx });
  };

  const handleConfirmation = (confirmed) => {
    if (confirmed && orderIdToDelete) {
      // Remove the order with the given order ID and set the status to "Rejected"
      const updatedOrders = orders
        .filter((order) => order._id !== orderIdToDelete)
        .map((order) => {
          if (order._id === orderIdToDelete) {
            return { ...order, status: "Rejected" };
          }

          return order;
        });
      setOrders(updatedOrders);
    }
    // Reset the order ID to delete
    setOrderIdToDelete(null);
    setIsConfirmationDialogOpen(false);
  };

  const handleRejectOrder = (orderId) => {
    setSelectedOrder(orderId);

    setIsConfirmationDialogOpen(true);
  };

  const handleAcceptOrder = (orderId) => {
    // Set the order status to "Accepted"
    console.log({ orderId });
    setSelectedOrder(orderId);
    setOpenModal(true);

    // Update the order status in the orders state
    const updatedOrders = orders.map((order) => {
      if (order._id === orderId) {
        return { ...order, status: "" };
      }
      return order;
    });

    setOrders(updatedOrders);

    setIsConfirmationDialogOpen(false);
  };

  return (
    <Box width="90vw" margin="auto">
      <h1>Product Order Display Page</h1>

      <TableContainer component={Paper} sx={{ my: "30px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          {/* <TableHead> */}
          <TableRow sx={{ fontWeight: 700 }}>
            <TableCell>Action</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Order Number</TableCell>
            <TableCell>Assigned category</TableCell>
            <TableCell>Order Status</TableCell>
          </TableRow>
          {/* </TableHead> */}
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell className="showMacAddress">
                  {" "}
                  <Button
                    variant="contained"
                    style={{ background: "green", color: "white" }}
                    onClick={() => {
                      // handleAcceptOrder(order._id);
                      sendTransaction();
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="contained"
                    style={{ background: "red", color: "white" }}
                    onClick={() => {
                      handleRejectOrder(order._id);
                    }}
                  >
                    Reject
                  </Button>
                </TableCell>
                <TableCell>{order.email}</TableCell>
                <TableCell>{order.orderNumber}</TableCell>
                <TableCell>{order.category}</TableCell>
                <TableCell>{order.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={!!openModal} onClose={() => setOpenModal(null)}>
        <DialogTitle>Select Category</DialogTitle>
        <DialogContent>
          <DialogContentText>Assign a category for this order:</DialogContentText>
          {categories.map((category) => (
            <Button
              key={category}
              variant={selectedCategory === category ? "contained" : "outlined"}
              color="primary"
              style={{
                color: "blue",
                "&:hover": {
                  background: "blue",
                  color: "white",
                },
                margin: "5px 10px  ",
              }}
              onClick={() => handleCategorySelection(category)}
            >
              {category}
            </Button>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAssignCategory} color="primary">
            Assign
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isConfirmationDialogOpen}
        onClose={() => {
          setIsConfirmationDialogOpen(false);
          setOpenModal(false);
        }}
      >
        <DialogTitle>Confirm Reject</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to reject this order?</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setIsConfirmationDialogOpen(false);
              setOpenModal(false);
            }}
            color="primary"
          >
            No
          </Button>
          <Button color="primary" onClick={rejectNFT}>
            {/*   */}
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminPage;
