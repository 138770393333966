import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Dashboard from "layouts/dashboard";
import Payment from "./subscription";
import { Theme as MintApp } from "components/common/Theme";

// @mui icons
import Icon from "@mui/material/Icon";
import NFT from "pages/NFT";
import { Home } from "pages/Home/Home";
import Data from "pages/Data/Data";
import AdminLogin from "layouts/authentication/admin";
import AdminSignup from "layouts/authentication/admin/AdminSignup";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },

  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  // {
  //   type: "collapse",
  //   name: "Admin Login",
  //   key: "Admin",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/admin/login",
  //   component: <AdminLogin />,
  // },
  // {
  //   type: "collapse",
  //   name: "Admin Signup",
  //   key: "Admin",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/admin/signup",
  //   component: <AdminSignup />,
  // },
  {
    type: "collapse",
    name: "Subscription",
    key: "subscription",
    icon: <Icon fontSize="small">subscriptions</Icon>,
    route: "/subscription",
    component: <Payment />,
  },
  {
    type: "collapse",
    name: "NFT",
    key: "NFT",
    icon: <Icon fontSize="small">NFT</Icon>,
    route: "/nft",
    component: <NFT />,
  },
  {
    type: "collapse",
    name: "Mint",
    key: "mint",
    icon: <Icon fontSize="small">payment</Icon>,
    route: "/mint",
    component: <MintApp />,
  },
  {
    type: "collapse",
    name: "Send",
    key: "send",
    icon: <Icon fontSize="small">send</Icon>,
    route: "/send",
    component: <Home />,
  },
  {
    type: "collapse",
    name: "Data",
    key: "data",
    icon: <Icon fontSize="small">data</Icon>,
    route: "/data",
    component: <Data />,
  },
];

export default routes;
