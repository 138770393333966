// OrderAssignedSection.js

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const OrderAssignedSection = ({ assignedOrders }) => {
  console.log({ assignedOrders });
  const tableCellStyle = {
    textAlign: "center", // Center-align the text in all cells
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <h2>Assigned Orders</h2>
      <TableContainer component={Paper} sx={{ my: "30px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          {/* <TableHead> */}
          <TableRow>
            <TableCell style={tableCellStyle}>Order ID</TableCell>
            <TableCell style={tableCellStyle}>Order Platform</TableCell>
            <TableCell style={tableCellStyle}>Category</TableCell>
            <TableCell style={tableCellStyle}>Status</TableCell>
          </TableRow>
          {/* </TableHead> */}
          <TableBody>
            {assignedOrders.map((order, index) => (
              <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell style={tableCellStyle}>{order.orderNumber}</TableCell>
                <TableCell style={tableCellStyle}>{order.orderPlatform}</TableCell>
                <TableCell style={tableCellStyle}>{order.category ?? ""}</TableCell>
                <TableCell
                  style={{
                    ...tableCellStyle,
                    color:
                      order.status === "Accepted"
                        ? "green"
                        : order.status === "Rejected"
                        ? "red"
                        : "orange",
                  }}
                >
                  {order.status ?? "Pending"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OrderAssignedSection;
