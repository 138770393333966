import React from 'react'
import './style.css'

const index = () => {
  return (
    <>
        <div className="staking-card">
            <span>Staked NFTS - 5</span><br />
            <span>Earnings - 50.4KHLR</span><br />
            <button>Withdraw</button>
        </div>
    </>
  )
}

export default index